import React, { useEffect, useState, useRef } from "react";
import { useAppContext } from "@/components/AppContext";
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { YODA_CDN_URL, PUJA_PRESIGN_URL, A4B_APPS } from "@/utils/constants";
import S3Upload from "@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload";
import { Filter } from "@a4b/api/src/modules/Monetisation/Puja/types/filters";
import { prepareFilterPayload } from "../../utils/helper";
import { useSearchParams } from "react-router-dom";

interface FilterFormProps {
    category: { id: string; name: string; viewType: string };
    currentFilter: Filter | undefined;
    handleCloseForm: (updateCategoryList?: boolean) => void;
}

const FilterForm: React.FC<FilterFormProps> = ({ category, currentFilter, handleCloseForm }) => {
    const [filterForm] = useForm();
    const { networkInstance, app } = useAppContext();
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi;
    const [searchParams] = useSearchParams()

    const isSubmittingRef = useRef<boolean>(false);
    const [isEnabled, setIsEnabled] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isImageRequired, setIsImageRequired] = useState<boolean>(false)

    useEffect(() => {
        if (!isSubmittingRef?.current) {
            if (currentFilter && isEdit) {
                filterForm?.setFieldsValue(currentFilter);
            } else {
                filterForm?.resetFields();
                filterForm?.setFieldsValue({ category_id: category?.id })
            }
        }
        // if (category?.viewType === 'GRID') {
        //     setIsImageRequired(true)
        // }
    }, [category, currentFilter, filterForm, isEdit])

    useEffect(() => {
        setIsEdit(searchParams?.get('edit-filter') === 'true')
    }, [searchParams])

    const handleCreateUpdateFilter = async (values: Filter) => {
        const payload: any = prepareFilterPayload(values)
        if (isEdit) {
            try {
                await monetisationPujaApi.filtersApi.updateFilter(values?.id, payload);
                message.success({ content: "Filter updated Successfully", duration: 3 });
                filterForm?.resetFields();
                handleCloseForm(true)
            } catch (error: any) {
                filterForm?.resetFields();
                message.error({ content: `Error while updating Filter — ${error?.response?.status?.toString()}`, duration: 3 });
                console.log({ error });
            }
        } else {
            try {
                await monetisationPujaApi.filtersApi.createFilter(payload);
                message.success({ content: "Filter created Successfully", duration: 3 });
                filterForm?.resetFields();
                handleCloseForm(true)
            } catch (error: any) {
                filterForm?.resetFields();
                message.error({ content: `Error while creating Filter — ${error?.response?.status?.toString()}`, duration: 3 });
                console.log({ error });
            }
        }
        // setTimeout(() => {
        //     handleCloseForm(true)
        // }, 5000)
        isSubmittingRef.current = false;
    };

    const handleDeleteFilter = async (values: Filter) => {
        try {
            await monetisationPujaApi.filtersApi.deleteFilter(values?.id);
            message.success({ content: "Filter deleted", duration: 3 });
            handleCloseForm(true)
        } catch (error: any) {
            message.error({ content: `Error while deleting Filter — ${error?.response?.status?.toString()}`, duration: 3 });
            console.log({ error });
        }
    }

    const submitForm = () => {
        isSubmittingRef.current = true;
        filterForm
            .validateFields()
            .then((values: Filter) => {
                handleCreateUpdateFilter(values);
            })
            .catch((error) => {
                console.log("Filter form error", error);
                isSubmittingRef.current = false;
            });
    };

    const deleteForm = () => {
        isSubmittingRef.current = true;
        filterForm
            .validateFields()
            .then((values: Filter) => {
                handleDeleteFilter(values);
            })
            .catch((error) => {
                console.log("Filter form error", error);
                isSubmittingRef.current = false;
            });
    };


    return (
        <Form
            form={filterForm}
            initialValues={currentFilter}
            layout="vertical"
            onFinish={submitForm}
            onFieldsChange={() => setIsEnabled(true)}
            style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "start" }}
        >
            <Col style={{ width: "100%", border: "1.5px dashed #d9d9d9", padding: "16px" }}>
                <div>
                    {
                        isEdit &&
                        <Form.Item
                            label="Filter ID"
                            name="id"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input disabled />
                        </Form.Item>
                    }
                    <Form.Item
                        label="Category ID"
                        name="category_id"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Name(Hindi)"
                        name="name"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: "Please input name of the Filter!" }]}
                    >
                        <Input placeholder="Enter Hindi Filter name" />
                    </Form.Item>
                    <Form.Item
                        label="Name(English)"
                        name="name_en"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: "Please input name of the Filter!" }]}
                    >
                        <Input placeholder="Enter English Filter name" />
                    </Form.Item>
                    <Form.Item
                        label="Slug"
                        name="slug"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: "Please input name of the Slug!" }]}
                    >
                        <Input placeholder="Enter slug" />
                    </Form.Item>
                    <Form.Item
                        label="Description (Hindi)"
                        name="description"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: false }]}
                    >
                        <Input placeholder="Enter Hindi description" />
                    </Form.Item>

                    <Form.Item
                        label="Description (English)"
                        name="description_en"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        dependencies={['description']}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!getFieldValue('description') || value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Description (English) is required if Description (Hindi) is provided.'));
                                },
                            }),
                        ]}
                    >
                        <Input placeholder="Enter English description" />
                    </Form.Item>
                    <Row justify="space-between">
                        <Form.Item
                            label="Image"
                            name="image"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            valuePropName="fileList"
                            rules={[
                                {
                                    min: isImageRequired ? 1 : 0,
                                    max: 1,
                                    validator: async (rules: any, values: any) => {
                                        if (values?.length < rules.min) {
                                            return Promise.reject('Please upload Image')
                                        }
                                    },
                                },
                            ]}
                        >
                            <S3Upload cdnUrl={YODA_CDN_URL} preSignUrl={PUJA_PRESIGN_URL} min={isImageRequired ? 1 : 0} max={1} />
                        </Form.Item>
                        <Form.Item
                            label="Date"
                            name={["meta_data", "date"]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ required: false, message: "Please input Date!" }]}
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item
                            label={"Priority"}
                            name={"priority"}
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Row>
                </div>
            </Col>
            <Row style={{ width: '100%' }} justify="space-between">
                <Button ghost type="primary" style={{ marginTop: "20px", width: '48%' }} htmlType="submit" disabled={!isEnabled}>
                    {isEdit ? "Update" : "Create"}
                </Button>
                <Button onClick={deleteForm} danger style={{ marginTop: "20px", width: '48%', marginLeft: '5px', display: isEdit ? 'block' : 'none' }} htmlType="button" disabled={!isEnabled}>
                    Delete
                </Button>
            </Row>
        </Form>
    );
};

export default React.memo(FilterForm);
