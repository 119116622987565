import { useMemo } from 'react';
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import 'antd/dist/antd.css';
import { Button, Checkbox, Form, Input, InputNumber, Radio, Select, Switch, TablePaginationConfig, Tag, message, } from 'antd';
import { SocialScreenContainer } from '@a4b/ui/src/admin';
import { openNotification } from '@/utils';
import { Shrine } from '@/../../../packages/a4b-api/src/modules/Temple/types';
import { debounce } from 'lodash';
import FileUpload, { getEditImageFiles, getEditImageFilesWithPath, getPathFormFile, getTypeFormFile } from '@a4b/ui/src/modules/admin/components/FileUpload';
import useAntdPagination from '@/hooks/useAntdPagination';
import { FORM_CONFIG, FORM_ITEM_TYPE, GOD_ITEMS, GOD_ITEM_TYPES } from './dynamic-items-definitions';
import { CopyOutlined } from '@ant-design/icons';
import moment from 'moment';

const requiredRule = [{ required: true }]

const ITEM_FORM_DEFAULT_FEILDS_SET = new Set(['code', 'type', 'access_type', 'thumbnail', 'attachment', 'coins_cost', 'validity_period', 'available_for_all_gods', 'days_active']);

const FREE_ITEM_TYPES = new Set([GOD_ITEM_TYPES.BACKGROUND, GOD_ITEM_TYPES.ARCH_LAYER, GOD_ITEM_TYPES.FLOOR_LAYER, GOD_ITEM_TYPES.MIDDLE_LAYER, GOD_ITEM_TYPES.CTA, GOD_ITEM_TYPES.ITEM_BASE])

const storeSelectedGodItem = (selectedGodItem: string) => {
  localStorage.setItem("SELECTED_GOD_ITEM", selectedGodItem);
}

const getStoredSelectedGodItem = () => {
  return localStorage.getItem("SELECTED_GOD_ITEM");
}

// console.log({ GOD_ITEMS })
const TempleGodItems = () => {
  const { networkInstance } = useAppContext();
  const [isCodeUnique, setIsCodeUnique] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [editItem, setEditItem] = useState<undefined | Shrine.Item>();
  const [associateItemId, setAssociateItemId] = useState<undefined | string>();
  const [godItems, setGodItems] = useState<Shrine.Item[]>([])
  const { pagination, setPagination } = useAntdPagination();
  const [form] = Form.useForm();
  const type = Form.useWatch('type', form);
  const access_type = Form.useWatch('access_type', form);
  const { itemType, godId } = useParams();
  const [freezeType, setFreezeType] = useState(false);
  const [selectedGodItem, setSelectedGodItem] = useState<GOD_ITEM_TYPES>(getStoredSelectedGodItem() as GOD_ITEM_TYPES || GOD_ITEM_TYPES.CHADAVA_ICON);

  const loadGodItems = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.shrineApi.getGodItems(pagination.pageSize, (pagination.current ? pagination.current - 1 : 0) * (pagination.pageSize || 50), selectedGodItem);
      setGodItems(res.data.data.items);
      if (pagination.total === -1) {
        setPagination({ ...pagination, total: res.data.data.count })
      }
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.shrineApi, pagination, selectedGodItem, setPagination])

  useEffect(() => {
    loadGodItems()
  }, [loadGodItems])

  useEffect(() => {
    const itemCopy: any = { ...editItem };
    // @ts-ignore
    itemCopy['attachment'] = editItem?.attachment ? getEditImageFiles([editItem['attachment']]) : undefined;
    itemCopy['thumbnail'] = editItem?.thumbnail ? getEditImageFiles([editItem['thumbnail']]) : undefined;

    if (editItem?.property) {
      Object.keys(editItem.property).forEach((propertyKey) => {
        // @ts-ignore
        const config = FORM_CONFIG[editItem.type];
        if (config[propertyKey]?.type === FORM_ITEM_TYPE.STRING || config[propertyKey]?.type === FORM_ITEM_TYPE.NUMBER) {
          // @ts-ignore 
          itemCopy[propertyKey] = editItem.property[propertyKey]

        } else {
          //@ts-ignore
          if (propertyKey === 'states') {
            //@ts-ignore 
            itemCopy['0'] = editItem.property[propertyKey]['0'] ? getEditImageFiles([editItem.property[propertyKey]['0']]) : undefined
            //@ts-ignore 
            itemCopy['100'] = editItem.property[propertyKey]['100'] ? getEditImageFiles([editItem.property[propertyKey]['100']]) : undefined
            return
          }

          // @ts-ignore
          itemCopy[propertyKey] = editItem.property[propertyKey] && editItem.property[propertyKey].type !== '' ?
            //@ts-ignore
            getEditImageFiles([editItem.property[propertyKey]])
            : undefined
        }
      })
    }
    if (editItem?.access_type === 'coins_based') {
      itemCopy['coins_cost'] = editItem.coins_threshold
      itemCopy['validity_period'] = editItem.validity_prd_secs
    }
    if (editItem?.access_type === 'days_active_based') {
      itemCopy['coins_cost'] = editItem.coins_threshold
      itemCopy['validity_period'] = editItem.validity_prd_secs
      itemCopy['days_active'] = editItem.days_active_threshold
    }

    itemCopy['classification'] = editItem?.property?.['classification'] ? editItem?.property?.["classification"] : "others"

    // handling audio attechment url for item type is prayer 
    if (itemCopy.type === GOD_ITEM_TYPES.PRAYER) {
      itemCopy['audio_attachment'].path = itemCopy['audio_attachment'].url.replace(/^.*\/\/[^\/]+/, '');
      delete itemCopy['audio_attachment'].url
    }

    // handling date range for vishash 
    if (itemCopy.type === GOD_ITEM_TYPES.VISHESH) {
      // @ts-ignore
      itemCopy['date_range'] = [moment(editItem['start_time']), moment(editItem['end_time'])]
    }
    form.setFieldsValue(itemCopy)
  }, [editItem, form])


  const handleDrawerClose = useCallback(() => {
    setShowDrawer(false);
    setEditItem(undefined);
    form.resetFields();
  }, [form])

  const handleDrawerOpen = useCallback(() => {
    setShowDrawer(true);
  }, [setShowDrawer])

  const updateGodItem = useCallback(async (values) => {
    if (editItem?.id === undefined) return null;
    const payload: Shrine.CreateGodItemPayload = {
      code: values.code,
      type: values.type,
      attachment: {
        path: getPathFormFile(values.attachment),
        type: getTypeFormFile(values.attachment)
      },
      property: {},
      access_type: values.access_type,
      available_for_all_gods: !!values.available_for_all_gods
    }
    if (values.thumbnail) {
      payload['thumbnail'] = {
        path: getPathFormFile(values.thumbnail),
        type: getTypeFormFile(values.thumbnail)
      }
    }

    if (values.access_type === 'coins_based') {
      payload['coins_cost'] = values.coins_cost
      payload['validity_period'] = values.validity_period
    }
    if (values.access_type === 'days_active_based') {
      payload['coins_cost'] = values.coins_cost
      payload['days_active'] = values.days_active
      payload['validity_period'] = values.validity_period
    }

    const property: { [key: string]: string | { type: string, path: string } } = {};
    Object.keys(values).forEach((fieldName: string) => {
      if (!ITEM_FORM_DEFAULT_FEILDS_SET.has(fieldName)) {
        // @ts-ignore 
        const godItem = FORM_CONFIG[values.type]
        if (godItem[fieldName].type === FORM_ITEM_TYPE.DATE_RANGE) {
          // @ts-ignore
          payload[godItem[fieldName].field_names[0]] = moment(values[fieldName][0]).startOf('day').valueOf()
          // @ts-ignore
          payload[godItem[fieldName].field_names[1]] = moment(values[fieldName][1]).endOf('day').valueOf()
          return
        }
        if (godItem[fieldName].type === FORM_ITEM_TYPE.STRING || godItem[fieldName].type === FORM_ITEM_TYPE.NUMBER) {
          property[fieldName] = values[fieldName]
        } else {
          property[fieldName] = {
            path: getPathFormFile(values[fieldName]),
            type: getTypeFormFile(values[fieldName])
          }
        }
      }
    })
    if (values.type === GOD_ITEM_TYPES.IGNITABLE) {
      //@ts-ignore
      property['states'] = {};
      //@ts-ignore
      property['states']['0'] = property['0'];
      //@ts-ignore
      property['states']['100'] = property['100'];
      delete property['100'];
      delete property['0'];
    }
    payload['property'] = property;

    // removing coin cost and validity period for prayer type
    if (payload.type === GOD_ITEM_TYPES.PRAYER) {
      delete payload['coins_cost']
      delete payload['validity_period']
    }
    try {
      await networkInstance.clientWithHeaders.shrineApi.updateGodItem(editItem?.id, payload)
      openNotification('success', { message: 'god item updated successfully!' })
      form.resetFields();
      setShowDrawer(false);
      loadGodItems();
    } catch (error: any) {
      openNotification('error', { message: 'got item updation failed!', description: error.toString(), })
    }
  }, [editItem?.id, form, loadGodItems, networkInstance.clientWithHeaders.shrineApi])



  const createGodItem = useCallback(async (values) => {
    const payload: Shrine.CreateGodItemPayload = {
      code: values.code,
      type: values.type,
      attachment: {
        path: getPathFormFile(values.attachment),
        type: getTypeFormFile(values.attachment)
      },
      property: {},
      access_type: values.access_type,
      available_for_all_gods: !!values.available_for_all_gods
    }
    if (values.thumbnail) {
      payload['thumbnail'] = {
        path: getPathFormFile(values.thumbnail),
        type: getTypeFormFile(values.thumbnail)
      }
    }
    if (values.access_type === 'coins_based') {
      payload['coins_cost'] = values.coins_cost
      payload['validity_period'] = values.validity_period
    }
    if (values.access_type === 'days_active_based') {
      payload['coins_cost'] = values.coins_cost
      payload['days_active'] = values.days_active
      payload['validity_period'] = values.validity_period
    }
    if (values.access_type === 'free') {
      payload['coins_cost'] = 0
      payload['validity_period'] = 0
    }

    // string up property based on types 
    const property: { [key: string]: string | { type: string, path: string } } = {};
    Object.keys(values).forEach((fieldName: string) => {
      if (!ITEM_FORM_DEFAULT_FEILDS_SET.has(fieldName)) {
        // @ts-ignore 
        const godItem = FORM_CONFIG[values.type]
        if (godItem[fieldName].type === FORM_ITEM_TYPE.DATE_RANGE) {
          // @ts-ignore
          payload[godItem[fieldName].field_names[0]] = moment(values[fieldName][0]).startOf('day').valueOf()
          // @ts-ignore
          payload[godItem[fieldName].field_names[1]] = moment(values[fieldName][1]).endOf('day').valueOf()
          return
        }
        if (godItem[fieldName].type === FORM_ITEM_TYPE.STRING || godItem[fieldName].type === FORM_ITEM_TYPE.NUMBER) {
          property[fieldName] = values[fieldName]
        } else {
          property[fieldName] = {
            path: getPathFormFile(values[fieldName]),
            type: getTypeFormFile(values[fieldName])
          }
        }
      }
    })
    if (values.type === GOD_ITEM_TYPES.IGNITABLE) {
      //@ts-ignore
      property['states'] = {};
      //@ts-ignore
      property['states']['0'] = property['0'];
      //@ts-ignore
      property['states']['100'] = property['100'];
      delete property['100'];
      delete property['0'];
    }
    payload['property'] = property;

    // removing coin cost and validity period for prayer type
    if (payload.type === GOD_ITEM_TYPES.PRAYER) {
      delete payload['coins_cost']
      delete payload['validity_period']
    }

    try {
      await networkInstance.clientWithHeaders.shrineApi.createGodItem(payload)
      openNotification('success', { message: 'god item created successfully!' })
      form.resetFields();
      loadGodItems();
    } catch (error: any) {
      openNotification('error', { message: 'got item creation failed!', description: error.toString(), })
    }
  }, [form, loadGodItems, networkInstance.clientWithHeaders.shrineApi])

  const checkIsCodeUnique = debounce(useCallback(async (code) => {
    try {
      const res = await networkInstance.clientWithHeaders.shrineApi.checkGodItemCodeUnique(code);
      setIsCodeUnique(res.data.data.is_unique);
    } catch (error) {
      console.log(error)
      setIsCodeUnique(false);
    }
  }, [networkInstance.clientWithHeaders.shrineApi]), 1000)


  const getFileUploadUrl = useCallback(async (file) => {
    if (!freezeType) {
      setFreezeType(true);
    }
    // try {
    const res = await networkInstance.clientWithHeaders.shrineApi.getS3SignedUrl(file.name, 'items', form.getFieldValue('type'));
    return res.data.data.info
    // } catch (error) {

    // }
  }, [form, freezeType, networkInstance.clientWithHeaders.shrineApi])

  const [messageApi, contextHolder] = message.useMessage();


  const info = useMemo(() => () => {
    messageApi.info('Copied!!');
  }, [messageApi]);

  const code = Form.useWatch('code', form);


  const drawerContent = useMemo(() => {
    return <Form
      form={form}
      onFinish={editItem ? updateGodItem : createGodItem}
      layout="vertical"
    >
      <Form.Item
        label="God item type"
        name="type"
        rules={[{ required: true, message: 'Please input name!' }]}
      >
        <Select
          onChange={(val) => {
            // setting default value for code 
            form.setFieldValue('code', val + '_')

            // setting static access type free for free items 
            if (FREE_ITEM_TYPES.has(val)) {
              form.setFieldValue('access_type', 'free')
            }
          }}
          disabled={!!editItem}
        >
          {GOD_ITEMS.map((item) => <Select.Option value={item}>{item}</Select.Option>)}
        </Select>
      </Form.Item>
      {
        type && <> <Form.Item
          label="Code"
          name="code"
          rules={editItem ? [] : [{
            required: true, validator: (rule, value, callback) => {
              let regex = new RegExp(/^[a-z0-9_]+$/);
              checkIsCodeUnique(value);
              if (!regex.test(value)) callback("Only small case english latters, numbers and _ is allowed."); else callback(undefined);
            }
          }]}

          hasFeedback
          validateStatus={editItem ? 'success' : isCodeUnique ? 'success' : 'error'}
        >
          <Input maxLength={32} disabled={!!editItem} />
        </Form.Item>
          <p style={{ color: 'red' }}>{!editItem && !isCodeUnique && 'Please enter a valid code'} </p>
          {
            type !== GOD_ITEM_TYPES.PRAYER &&
            <FileUpload rules={requiredRule} name={'thumbnail'} accept={`.png,.jpg,.jpeg,.gif,.webp,${code === 'cta_right_2_new_default' ? 'application/JSON' : ''}`} getS3PathApi={getFileUploadUrl} title={'upload image'} />
          }
          <FileUpload rules={requiredRule} name={'attachment'} accept={`.png,.jpg,.jpeg,.gif,.webp,${code === 'cta_right_2_new_default' ? 'application/JSON' : ''}`} getS3PathApi={getFileUploadUrl} title={'upload image'} />
          {
            //@ts-ignore
            type && FORM_CONFIG[type].propertyForm && FORM_CONFIG[type].propertyForm({ getFileUploadUrl })
          }
          <Form.Item
            label="Access types"
            name="access_type"
            rules={[{ required: true }]}
          >
            <Radio.Group disabled={FREE_ITEM_TYPES.has(type) || ((editItem?.access_type === 'free' || editItem?.access_type === 'vip'))} >
              <Radio disabled={!!editItem} value={'free'}>Free</Radio>
              <Radio value={'coins_based'}>Coins based</Radio>
              <Radio value={'days_active_based'}>Days active based</Radio>
              <Radio disabled={!!editItem} value={'vip'}>VIP</Radio>
            </Radio.Group>
          </Form.Item>
          {
            access_type === 'coins_based' && <>
              <Form.Item
                label="Coins cost"
                name="coins_cost"
                rules={[{ required: true }]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Validity Period (In seconds)"
                name="validity_period"
                rules={[{ required: true }]}
              >
                {/* <DatePicker
              format="MM-DD HH:mm:ss"
            // showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
            /> */}
                <Select style={{ width: '200px' }} >
                  <Select.Option value={43200}> 12 Hours </Select.Option>
                  <Select.Option value={86400}> 1 Day </Select.Option>
                  <Select.Option value={777600}> 9 Days </Select.Option>
                  <Select.Option value={31536000}> 1 year </Select.Option>
                </Select>
              </Form.Item>
            </>

          }
          {
            access_type === 'days_active_based' && <>
              <Form.Item
                label="Coins cost"
                name="coins_cost"
                rules={[{ required: true }]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Days active milestone"
                name="days_active"
                rules={[{ required: true }]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Validity Period (In seconds)"
                name="validity_period"
                rules={[{ required: true }]}
              >
                <Select style={{ width: '200px' }} >
                  <Select.Option value={43200}> 12 Hours </Select.Option>
                  <Select.Option value={86400}> 1 Day </Select.Option>
                  <Select.Option value={777600}> 9 Days </Select.Option>
                  <Select.Option value={31536000}> 1 year </Select.Option>
                </Select>
              </Form.Item>
            </>
          }
        </>
      }
      <Form.Item
        label={"Available for all gods"}
        name={"available_for_all_gods"}
        valuePropName='checked'
      >
        <Checkbox></Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" >Submit</Button>
        <Button style={{ marginLeft: '10px' }} htmlType="submit" onClick={() => { setFreezeType(false); form.resetFields(); }}>Reset</Button>
      </Form.Item>
    </Form>

  }, [access_type, checkIsCodeUnique, code, createGodItem, editItem, form, getFileUploadUrl, isCodeUnique, type, updateGodItem])


  const activateGod = useCallback(async (godId: string) => {
    const payload = {
      active: true
    }
    try {
      await networkInstance.clientWithHeaders.shrineApi.updateGodItem(godId, payload);
      openNotification('success', { message: 'God item activated successfully!' })
      const _godItems = godItems.map((godItem) => godItem.id === godId ? { ...godItem, active: !godItem.active } : godItem);
      setGodItems(_godItems);
    } catch (error: any) {
      openNotification('error', { message: 'God item activation failed!', description: error.toString(), })
    }
  }, [godItems, networkInstance.clientWithHeaders.shrineApi])


  const deactivateGod = useCallback(async (godId: string) => {
    const payload = {
      active: false
    }
    try {
      await networkInstance.clientWithHeaders.shrineApi.updateGodItem(godId, payload);
      openNotification('success', { message: 'God deactivated successfully!' })
      const _godItems = godItems.map((godItem) => godItem.id === godId ? { ...godItem, active: !godItem.active } : godItem);
      setGodItems(_godItems);
    } catch (error: any) {
      openNotification('error', { message: 'God deactivation failed!', description: error.toString(), })
    }
  }, [godItems, networkInstance.clientWithHeaders.shrineApi])

  const columns = useMemo(() => [
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnail',
      render: (thumbnail: { type: string, url: string }) => <img src={thumbnail.url} height={50} width={50} alt={'god image'} />
    },
    {
      title: 'Attachments',
      dataIndex: 'attachment',
      render: (attachment: { type: string, url: string }) => <img src={attachment.url} height={50} width={50} alt={'god image'} />
    },
    {
      title: 'ID / Code',
      width: 500,
      dataIndex: 'id',
      render: (id: string, data: Shrine.Item) => {
        return (
          <div>
            <span>{id} <CopyOutlined style={{ fontSize: 20 }} onClick={() => { navigator.clipboard.writeText(id); info(); }} /></span>
            <br /><br />
            <span><strong>Code:</strong> {data.code}</span>
          </div>
        )
      }
    },
    {
      title: 'Access type',
      dataIndex: 'access_type'
    },
    {
      title: 'Active',
      dataIndex: 'active',
      render: (isActive: boolean) => <Tag color={isActive ? 'green' : 'red'} > {isActive ? 'Yes' : 'No'}</Tag>
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (id: string, data: Shrine.Item) => <>
        <Switch checked={data.active} onClick={() => { data.active ? deactivateGod(id) : activateGod(id) }} checkedChildren={'Deactivate'} unCheckedChildren="Activate" />
        <Button type="link" onClick={() => { setEditItem(data); handleDrawerOpen(); }} > Edit </Button>
      </>
    },
  ], [activateGod, deactivateGod, handleDrawerOpen, info]);

  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }

  const filterUi = useMemo(() => {
    return <Select
      value={selectedGodItem}
      style={{ width: 300 }}
      onChange={(val: GOD_ITEM_TYPES) => { storeSelectedGodItem(val); setPagination({ ...pagination, total: -1 }); setSelectedGodItem(val) }}
    >
      {GOD_ITEMS.map((item) => <Select.Option value={item}>{item}</Select.Option>)}
    </Select>
  }, [pagination, selectedGodItem, setPagination])

  const drawerProps = useMemo(() => ({ title: associateItemId ? 'Associate item to god' : editItem ? 'Update god item' : 'Create god item', visible: showDrawer, onClose: handleDrawerClose, width: 900 }), [associateItemId, editItem, handleDrawerClose, showDrawer])

  return (
    <>
      {contextHolder}
      <SocialScreenContainer
        title={'Shrine god items'}
        drawerProps={drawerProps}
        drawerContent={drawerContent}
        tableProps={{ columns: columns, dataSource: godItems, pagination: pagination, onChange: tableOnChange, size: 'small', scroll: { y: 650 } }}
        extra={<Button onClick={handleDrawerOpen}>Create god item</Button>}
        filtersUI={filterUi}
      >
      </SocialScreenContainer>
    </>
  )
}

export default withDefaultLayout(TempleGodItems)
