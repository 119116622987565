import moment from 'moment'

import { Filter } from '@a4b/api/src/modules/Monetisation/Puja/types/filters'

export const prepareFilterPayload = (data: Partial<Filter>) => {
  const payload = {
    name: data?.name,
    name_en: data?.name_en,
    slug: data?.slug,
    priority: data?.priority,
    ...(data?.description && {
      description: data?.description,
    }),
    ...(data?.description && {
      description_en: data?.description_en,
    }),
    category_id: data?.category_id,
    ...(data?.image?.[0]?.length! > 0 && {
      image: data?.image?.[0],
    }),
    meta_data: {
      ...data?.meta_data,
      date: moment(data?.meta_data?.date)?.unix(),
    },
  }
  return payload
}

export const parseFilterData = (data: Partial<Filter>) => {
  const parsedData = {
    ...data,
    meta_data: {
      ...data?.meta_data,
      date: data?.meta_data?.date
        ? moment.unix(data?.meta_data?.date)
        : undefined,
    },
    image: [data?.image],
  }
  return parsedData
}
