import { useEffect, useState } from "react"
import { useAppContext } from "@/components/AppContext"
import { Button, Col, Drawer, Form, Input, InputNumber, Row, Select, Switch, message } from "antd"
import { useForm } from 'antd/lib/form/Form'
import { YODA_CDN_URL, PUJA_PRESIGN_URL, A4B_APPS } from '@/utils/constants'
import S3Upload from "@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload"
import { Category } from '@a4b/api/src/modules/Monetisation/Puja/types/categories'
import { useSearchParams } from "react-router-dom"

const { Option } = Select

interface CreateUpdateCategoryProps {
    showForm: boolean
    currentCategory: Category
    handleCloseForm: (updateStoreList?: boolean) => void
}

const CreateUpdateCategory: React.FC<CreateUpdateCategoryProps> = ({ showForm, currentCategory, handleCloseForm }) => {
    const [categoryForm] = useForm()
    const { networkInstance, app } = useAppContext()
    const [searchParams] = useSearchParams()
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
    const [isEnabled, setIsEnabled] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        setIsEdit(searchParams?.get('edit') === 'true')
    }, [searchParams])

    useEffect(() => {
        if (currentCategory && isEdit) {
            const { image, bg_img_url, ...rest } = currentCategory
            categoryForm.setFieldsValue({ image: [image], bg_img_url: bg_img_url ? [bg_img_url] : undefined, ...rest })
        }
    }, [categoryForm, currentCategory, isEdit])

    const handleValuesChange = (changedValues: any, allValues: any) => {
        const { is_app, is_web } = allValues;

        if (is_app && is_web) {
            message.warning('Both "Is App" and "Is Web" cannot be true at the same time.');
            categoryForm.setFieldsValue({ ...changedValues, ...{ [Object.keys(changedValues)[0]]: false } });
        }
    };
    const handleCreateCategory = async (values: any) => {
        const payload = { ...values, image: values?.image?.[0], bg_img_url: values?.bg_img_url?.[0] }
        if (isEdit) {
            try {
                await monetisationPujaApi.categoriesApi.updateCategory(currentCategory?.id, payload)
                message.success({ content: 'Category updated Successfully', duration: 3 })
            } catch (error) {
                message.error({ content: 'Error while updating Category', duration: 3 })
                console.log({ error })
            }
        } else {
            try {
                await monetisationPujaApi.categoriesApi.createCategory(payload)
                message.success({ content: 'Category created Successfully', duration: 3 })
            } catch (error) {
                message.error({ content: 'Error while creating Category', duration: 3 })
                console.log({ error })
            }
        }
        handleCloseForm(true)
    }

    const submitForm = () => {
        categoryForm
            .validateFields()
            .then((values: any) => {
                console.log({ values })
                handleCreateCategory(values)
            })
            .catch((error) => {
                console.log('Puja Update form error', error)
            })
    }

    return (
        <Drawer
            title={
                <Col style={{ width: '100%' }}>
                    <span>{isEdit ? 'Update Category' : 'Create Category'}</span>
                </Col>
            }
            placement='left'
            onClose={() => {
                handleCloseForm()
                categoryForm?.resetFields()
            }}
            visible={showForm}
            destroyOnClose={true}
            width={500}
        >
            <Form
                form={categoryForm}
                layout='vertical'
                initialValues={{ remember: true }}
                onFinish={submitForm}
                onFieldsChange={() => setIsEnabled(true)}
                onValuesChange={handleValuesChange}
                style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start' }}
            >
                <Col style={{ width: '100%' }}>
                    <div>
                        <Form.Item
                            label='Name(Hindi)'
                            name='name'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                { required: true, message: 'Please input name of the Category!' },
                            ]}
                        >
                            <Input placeholder='Enter Hindi Category name' />
                        </Form.Item>
                        <Form.Item
                            label='Name(English)'
                            name='name_en'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                { required: true, message: 'Please input name of the Category!' },
                            ]}
                        >
                            <Input placeholder='Enter English Category name' />
                        </Form.Item>
                        <Form.Item
                            label='Slug'
                            name='slug'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                { required: true, message: 'Please input name of the Slug!' },
                            ]}
                        >
                            <Input placeholder='Enter slug' />
                        </Form.Item>
                        <Row justify="space-between">
                            <Form.Item
                                label='View type'
                                name='view_type'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select view type!',
                                    },
                                ]}
                            >
                                <Select style={{ width: 100 }} placeholder='Type'>
                                    <Option value='LIST'>LIST</Option>
                                    <Option value='GRID'>GRID</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Image"
                                name='image'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                dependencies={['view_type']}
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            const viewType = getFieldValue('view_type');
                                            if (viewType === 'GRID' && (!value || value.length === 0)) {
                                                return Promise.reject(new Error('Please upload Image'));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                                valuePropName='fileList'
                            >
                                <S3Upload
                                    cdnUrl={YODA_CDN_URL}
                                    preSignUrl={PUJA_PRESIGN_URL}
                                    min={1}
                                    max={1}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Background Image"
                                name='bg_img_url'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                valuePropName='fileList'
                            >
                                <S3Upload
                                    cdnUrl={YODA_CDN_URL}
                                    preSignUrl={PUJA_PRESIGN_URL}
                                    min={0}
                                    max={1}
                                />
                            </Form.Item>
                        </Row>
                        <Row justify="space-between">
                            <Form.Item
                                label="Is Web"
                                name='is_web'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                label="Is App"
                                name='is_app'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                label={"Order"}
                                name={"order"}
                                labelCol={{ span: 12 }}
                                wrapperCol={{ span: 12 }}
                            >
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Row>
                    </div>
                    <Button style={{ marginTop: '20px' }} htmlType="submit" disabled={!isEnabled}>{isEdit ? 'Update' : 'Create'}</Button>
                </Col>
            </Form>
        </Drawer>
    )
}

export default CreateUpdateCategory