import FileUpload from "@a4b/ui/src/modules/admin/components/FileUpload";
import { ShrinkOutlined, ArrowsAltOutlined, UpCircleOutlined, DownCircleOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Checkbox, Form, FormInstance, Input, InputNumber, Radio, Select, Tooltip } from "antd";
import FormItem from "antd/es/form/FormItem";
import avatars from "..";
import { REQUIRED_RULE, SUPPORTED_IMAGE_ACCEPT_TYPE, godCodeValidation } from "../../../../../utils/form.validation";
import SimpleImageUplaod, { UPLOAD_TYPES } from "../../../../../components/Forms/SimpleImageUpload";
import TextArea from "antd/lib/input/TextArea";
import { Shrine } from "@a4b/api/src/modules/Temple/types";
import styled from "styled-components";
import { useAppContext } from "../../../../../components/AppContext";
import { useCallback } from "react";
import { v4 as uuidv4 } from 'uuid';


const SubmitButton = styled(Button)`
    position: absolute;
    top: 10px;
    right: 10px;
`


// This interface is just a example for AntdFormWrapper 
interface CreateAvatarFormProps {
    form: FormInstance<Shrine.AvatarPayload>,
    formData?: Shrine.AvatarPayload,
}


const CreateAvatarForm = ({ form, formData }: CreateAvatarFormProps) => {
    const { networkInstance } = useAppContext()

    async function s3UplaodApi(fileType?: { type: string; } | undefined, options?: any): Promise<{ url: string; path: string; type?: string | undefined; }> {
        // try {
        const res = await networkInstance.clientWithHeaders.shrineApi.getS3SignedUrl(uuidv4() + options?.file.name, 'system_gods', form.getFieldValue('code'));
        return res.data.data.info
        // } catch (error) {

        // }
    }

    const type = Form.useWatch('type', form);
    const access_type = Form.useWatch('access_type', form);

    return <div>
        <Form.Item name={"code"} label={"Code"} rules={REQUIRED_RULE}>
            <Input disabled={!!formData}></Input>
        </Form.Item>
        <Form.Item name={"type"} label="Type" rules={REQUIRED_RULE}>
            <Radio.Group
                // disabled={(editGod && !form.getFieldValue(['avatars', field.name, '__new'])) || isTypeTemple}
                // onChange={(e) => { if (e.target.value === 'default') { form.setFieldValue(['avatars', field.name, 'access_type'], 'free') } }}
                onChange={(e) => {
                    if (e.target.value === 'default') { form.setFieldValue(['access_type'], 'free') }
                }}
                disabled={type === 'temple' || !!formData}
            >
                <Radio value={'default'}>Default</Radio>
                <Radio value={'layered'}>Layered</Radio>
                <Radio disabled value={'temple'}>Temple</Radio>
                <Radio value={'promotional'}>Promotional</Radio>
            </Radio.Group>
        </Form.Item>
        <Form.Item name="position" label="Position" rules={REQUIRED_RULE}>
            <InputNumber></InputNumber>
        </Form.Item>
        <Form.Item name={"access_type"} label="Access type" rules={REQUIRED_RULE}>
            <Select
                // disabled={(editGod && !form.getFieldValue(['avatars', field.name, '__new'])) || isTypeTemple}
                disabled={(type !== 'layered' && type !== 'promotional') || !!formData}
            >
                <Select.Option disabled value={"paid"}>{'Paid'}</Select.Option>
                <Select.Option value={"free"}>{'Free'}</Select.Option>
            </Select>
        </Form.Item>
        <SimpleImageUplaod disabled={type === 'temple'} allowUploadUsingUrl getS3PathApi={s3UplaodApi} name={['attachment']} form={form} rules={REQUIRED_RULE} />
        <Form.Item name={["names", "default", 0]} label="Name default" rules={REQUIRED_RULE}>
            <Input disabled={type === 'temple'}></Input>
        </Form.Item>
        <Form.Item name={["names", "english", 0]} label="Name english" rules={REQUIRED_RULE}>
            <Input disabled={type === 'temple'}></Input>
        </Form.Item>

        <Form.Item name={["available_for_purchase"]} label="Available for purchase" rules={REQUIRED_RULE} initialValue={false} valuePropName="checked">
            <Checkbox disabled={access_type !== 'paid'}></Checkbox>
        </Form.Item>
        {
            access_type === 'paid' &&
            <>
                <SimpleImageUplaod allowUploadUsingUrl getS3PathApi={s3UplaodApi} name={["paid_avatar_details", "default_attachment"]} form={form} />
                <Form.Item name={["paid_avatar_details", "pricing", "INR"]} label="Pricing INR" rules={REQUIRED_RULE}>
                    <InputNumber></InputNumber>
                </Form.Item>
                <Form.Item name={["paid_avatar_details", "pricing", "USD"]} label="Pricing USD" rules={REQUIRED_RULE}>
                    <InputNumber></InputNumber>
                </Form.Item>
                <SimpleImageUplaod allowUploadUsingUrl defaultUploadType={UPLOAD_TYPES.LINK} rules={REQUIRED_RULE} accept="audio/*" getS3PathApi={s3UplaodApi} name={['paid_avatar_details', 'playback_audio']} form={form} />
                <SimpleImageUplaod allowUploadUsingUrl getS3PathApi={s3UplaodApi} name={['paid_avatar_details', 'playback_audio_image']} form={form} rules={REQUIRED_RULE} />
                <Form.Item
                    name={["paid_avatar_details", "content", "default", "title"]}
                    label="Title default"
                    rules={REQUIRED_RULE}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    name={["paid_avatar_details", "content", "default", "subtitle"]}
                    label="Subtitle default"
                    rules={REQUIRED_RULE}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    name={["paid_avatar_details", "content", "default", "description"]}
                    label="Description default"
                    rules={REQUIRED_RULE}
                >
                    <TextArea></TextArea>
                </Form.Item>
                <Form.Item
                    name={["paid_avatar_details", "content", "english", "title"]}
                    label="Title english"
                    rules={REQUIRED_RULE}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    name={["paid_avatar_details", "content", "english", "subtitle"]}
                    label="Subtitle english"
                    rules={REQUIRED_RULE}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    name={["paid_avatar_details", "content", "english", "description"]}
                    label="Description english"
                    rules={REQUIRED_RULE}
                >
                    <TextArea></TextArea>
                </Form.Item>
                <SimpleImageUplaod allowUploadUsingUrl getS3PathApi={s3UplaodApi} name={["paid_avatar_details", "lock_icon"]} form={form} />
                <SimpleImageUplaod allowUploadUsingUrl getS3PathApi={s3UplaodApi} name={["paid_avatar_details", "curtain_unlocked"]} form={form} />
                <SimpleImageUplaod allowUploadUsingUrl getS3PathApi={s3UplaodApi} name={["paid_avatar_details", "curtain_locked"]} form={form} />
            </>
        }
        {
            type === 'promotional' &&
            <div>
                <SimpleImageUplaod accept="audio/*" allowUploadUsingUrl getS3PathApi={s3UplaodApi} name={["promotional_avatar_details", "audio_attachment"]} form={form} />
                <Form.Item
                    name={["promotional_avatar_details", "content", "default", "title"]}
                    label="Title default"
                    rules={REQUIRED_RULE}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={["promotional_avatar_details", "content", "default", "subtitle"]}
                    label="Subtitle default"
                    rules={REQUIRED_RULE}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={["promotional_avatar_details", "content", "default", "description"]}
                    label="Description default"
                    rules={REQUIRED_RULE}
                >
                    <TextArea />
                </Form.Item>
                <Form.Item
                    name={["promotional_avatar_details", "content", "english", "title"]}
                    label="Title english"
                    rules={REQUIRED_RULE}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={["promotional_avatar_details", "content", "english", "subtitle"]}
                    label="Subtitle english"
                    rules={REQUIRED_RULE}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={["promotional_avatar_details", "content", "english", "description"]}
                    label="Description english"
                    rules={REQUIRED_RULE}
                >
                    <TextArea />
                </Form.Item>
                <Form.Item
                    name={["promotional_avatar_details", "cta", "default_text"]}
                    label="CTA Default Text"
                    rules={REQUIRED_RULE}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={["promotional_avatar_details", "cta", "english_text"]}
                    label="CTA English Text"
                    rules={REQUIRED_RULE}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={["promotional_avatar_details", "cta", "deeplink"]}
                    label="CTA Deeplink"
                    rules={REQUIRED_RULE}
                >
                    <Input />
                </Form.Item>
            </div>

        }
        <Form.Item name={["active"]} label="Active" rules={REQUIRED_RULE} initialValue={false} valuePropName="checked">
            <Checkbox></Checkbox>
        </Form.Item>

        {
            formData ?
                <SubmitButton type="primary" htmlType="submit"> Update Avatar</SubmitButton> :
                <SubmitButton type="primary" htmlType="submit"> Create Avatar</SubmitButton>
        }
    </div >
}

export default CreateAvatarForm;


