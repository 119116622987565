import { Shrine } from "@/../../../packages/a4b-api/src/modules/Temple/types";
import FileUpload, { getEditImageFiles, getPathFormFile, getTypeFormFile } from "@a4b/ui/src/modules/admin/components/FileUpload";
import { useAppContext } from "@/components/AppContext";
import { openErrorNotification, openNotification } from "@/utils";
import { v4 as uuidv4, validate } from 'uuid';
import { UpCircleOutlined, DownCircleOutlined, MinusCircleOutlined, PlusOutlined, ShrinkOutlined, ArrowsAltOutlined } from "@ant-design/icons";
import { Form, Input, InputNumber, Radio, Space, Checkbox, Button, Select, Alert, Tooltip, Card, Row, Col } from "antd";
import { debounce } from "lodash";
import { useCallback, useEffect, useReducer, useState } from "react";
import { godCodeValidation, REQUIRED_RULE, SUPPORTED_IMAGE_ACCEPT_TYPE } from "@/utils/form.validation";
import { SUPPORTED_LANGUAGES_LIST } from "@/utils/constants";
import { TS_GENERICS } from "@/utils/ts.generics";

export interface GodFormProps {
  loadGods: () => Promise<void>
  handleDrawerClose: () => void
  editGod?: Shrine.God
}

enum effects {
}

const initialState = {
  isCodeValid: false,
  freezeCodeFeild: false,
  freezeLanguageFeild: false,
  supportedlanguages: SUPPORTED_LANGUAGES_LIST,
  effects: Object.values(effects)
};

enum types {
  SET_CODE_VALID = 'SET_CODE_VALID',
  SET_FREEZE_CODE_FEILD = 'TOOGLE_FREEZE_CODE_FEILD',
  SET_FREEZE_LANGUAGE_FEILD = 'TOGGLE_FREEZE_LANGUAGE_FEILD',
  UPDATE_SUPPPORTED_LANGUAGES = 'UPDATE_SUPPPORTED_LANGUAGES',
  RESET_REDUCER = 'RESET_REDUCER'
}

type actionTypes = TS_GENERICS.REDUCER_ACTION<types.SET_CODE_VALID, boolean>
  | TS_GENERICS.REDUCER_ACTION<types.SET_FREEZE_CODE_FEILD, boolean>
  | TS_GENERICS.REDUCER_ACTION<types.SET_FREEZE_LANGUAGE_FEILD, boolean>
  | TS_GENERICS.REDUCER_ACTION<types.UPDATE_SUPPPORTED_LANGUAGES, [string]>
  | TS_GENERICS.REDUCER_ACTION<types.RESET_REDUCER, undefined>


function reducer(state: typeof initialState, action: actionTypes): typeof initialState {
  switch (action.type) {
    case types.SET_CODE_VALID:
      return { ...state, isCodeValid: action.payload };
    case types.SET_FREEZE_CODE_FEILD:
      return { ...state, freezeCodeFeild: action.payload }
    case types.SET_FREEZE_LANGUAGE_FEILD:
      return { ...state, freezeLanguageFeild: action.payload }
    case types.UPDATE_SUPPPORTED_LANGUAGES:
      return { ...state, supportedlanguages: SUPPORTED_LANGUAGES_LIST.filter(language => !action.payload.includes(language.value)) }
    case types.RESET_REDUCER:
      return initialState;
    default:
      throw new Error();
  }
}



const GodForm = ({ editGod, loadGods, handleDrawerClose }: GodFormProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { networkInstance } = useAppContext();
  const [viewType, setViewType] = useState<'expanded' | 'collapsed'>('expanded');

  const checkIsCodeIsValid = debounce(useCallback(async (code) => {
    try {
      const checkValidRes = await networkInstance.clientWithHeaders.shrineApi.checkCodeValid(code);
      dispatch({ type: types.SET_CODE_VALID, payload: checkValidRes.data.data.is_valid });
    } catch (error) {
      dispatch({ type: types.SET_CODE_VALID, payload: false });
    }
  }, [networkInstance.clientWithHeaders.shrineApi]), 1000)

  const [form] = Form.useForm();
  const avatars = Form.useWatch('avatars', form);
  const codeValidation = state.isCodeValid ? 'success' : 'error';

  useEffect(() => {
    if (editGod) {
      dispatch({ type: types.SET_FREEZE_CODE_FEILD, payload: true })
      dispatch({ type: types.SET_CODE_VALID, payload: true })
      dispatch({ type: types.SET_FREEZE_LANGUAGE_FEILD, payload: true })
    }
  }, [editGod])


  const checkIsCodeIsUnique = useCallback(async (languageCode: string) => {
    try {
      if (!form.getFieldValue('code')) {
        return false;
      }
      const checkUniqueRes = await networkInstance.clientWithHeaders.shrineApi.checkCodeUnique(form.getFieldValue('code'), languageCode);
      if (checkUniqueRes.data.data.is_unique) {
        if (!state.freezeCodeFeild) {
          dispatch({ type: types.SET_FREEZE_CODE_FEILD, payload: true })
        }
        return true;
      }
    } catch (error) {

    }
    return false;
  }, [form, networkInstance.clientWithHeaders.shrineApi, state.freezeCodeFeild])

  const getFileUploadUrl = useCallback(async (file) => {
    if (!state.freezeCodeFeild && codeValidation === 'success') {
      dispatch({ type: types.SET_FREEZE_CODE_FEILD, payload: true })
    }
    // try {
    const res = await networkInstance.clientWithHeaders.shrineApi.getS3SignedUrl(uuidv4() + file.name, 'system_gods', form.getFieldValue('code'));
    return res.data.data.info
    // } catch (error) {

    // }
  }, [codeValidation, form, networkInstance.clientWithHeaders.shrineApi, state.freezeCodeFeild])


  const createGod = useCallback(async (values) => {
    const valuesCopy = JSON.parse(JSON.stringify(values));
    const payload: any = {
      code: valuesCopy.code,
      thumbnails: {
        large: {
          path: getPathFormFile(valuesCopy.largeThumbnail),
          type: getTypeFormFile(valuesCopy.largeThumbnail)
        },
        mini: {
          path: getPathFormFile(valuesCopy.miniThumbnail),
          type: getTypeFormFile(valuesCopy.miniThumbnail)
        },
        small_round: {
          path: getPathFormFile(valuesCopy.smallRoundThumbnail),
          type: getTypeFormFile(valuesCopy.smallRoundThumbnail)
        },
        small_square: {
          path: getPathFormFile(valuesCopy.smallSquareThumbnail),
          type: getTypeFormFile(valuesCopy.smallSquareThumbnail)
        },
      },
      languages: valuesCopy.languages.map((language: any) => language.language_code.value),
      festive_special: values.displayStatus === 'festive_special',
      position: valuesCopy.position,
      pre_selected: valuesCopy.displayStatus === 'pre_selected',
      loader_text: values.loader_text
    }
    try {
      await networkInstance.clientWithHeaders.shrineApi.createGod(payload)
      openNotification('success', { message: 'God created successfully!' })
      form.resetFields();
      dispatch({ type: types.RESET_REDUCER, payload: undefined })
      loadGods();
      handleDrawerClose();
    } catch (error: any) {
      openNotification('error', { message: 'God creation failed!', description: error.toString(), })
    }
  }, [form, handleDrawerClose, loadGods, networkInstance.clientWithHeaders.shrineApi])

  const updateGod = useCallback(async (values) => {
    const valuesCopy = JSON.parse(JSON.stringify(values));
    if (editGod?.id === undefined) return null;
    const payload = {
      code: valuesCopy.code,
      thumbnails: {
        large: {
          path: getPathFormFile(valuesCopy.largeThumbnail),
          type: getTypeFormFile(valuesCopy.largeThumbnail)
        },
        mini: {
          path: getPathFormFile(valuesCopy.miniThumbnail),
          type: getTypeFormFile(valuesCopy.miniThumbnail)
        },
        small_round: {
          path: getPathFormFile(valuesCopy.smallRoundThumbnail),
          type: getTypeFormFile(valuesCopy.smallRoundThumbnail)
        },
        small_square: {
          path: getPathFormFile(valuesCopy.smallSquareThumbnail),
          type: getTypeFormFile(valuesCopy.smallSquareThumbnail)
        },
      },
      festive_special: values.displayStatus === 'festive_special',
      position: values.position,
      pre_selected: values.displayStatus === 'pre_selected',
      loader_text: values.loader_text
    }

    try {
      await networkInstance.clientWithHeaders.shrineApi.updateGod(editGod?.id, payload)
      openNotification('success', { message: 'God updated successfully!' })
      form.resetFields();
      dispatch({ type: types.RESET_REDUCER, payload: undefined });
      loadGods();
      handleDrawerClose();
    } catch (error: any) {
      openNotification('error', { message: 'God updation failed!', description: error.toString(), })
      openErrorNotification(error);
    }
  }, [editGod?.id, form, handleDrawerClose, loadGods, networkInstance.clientWithHeaders.shrineApi])



  useEffect(() => {
    if (!editGod) return;
    const godCopy: any = { ...editGod };
    godCopy['largeThumbnail'] = editGod?.thumbnails.large ? getEditImageFiles([{ url: editGod?.thumbnails.large.url, type: editGod?.thumbnails.large.type }]) : undefined;
    godCopy['miniThumbnail'] = editGod?.thumbnails.mini ? getEditImageFiles([{ url: editGod?.thumbnails.mini.url, type: editGod?.thumbnails.mini.type }]) : undefined;
    godCopy['smallRoundThumbnail'] = editGod?.thumbnails.small_round ? getEditImageFiles([{ url: editGod?.thumbnails.small_round.url, type: editGod?.thumbnails.small_round.type }]) : undefined;
    godCopy['smallSquareThumbnail'] = editGod?.thumbnails.small_square ? getEditImageFiles([{ url: editGod?.thumbnails.small_square.url, type: editGod?.thumbnails.small_square.type }]) : undefined;
    godCopy['displayStatus'] = editGod?.festive_special ? 'festive_special' : editGod?.pre_selected ? 'pre_selected' : 'none';
    godCopy['languages'] = [{ language_code: editGod?.language_code }]
    form.setFieldsValue(godCopy)
  }, [editGod, form])

  const resetForm = () => {
    dispatch({ type: types.RESET_REDUCER, payload: undefined })
    form.resetFields()
  }

  return <Form
    form={form}
    onFinish={editGod ? updateGod : createGod}
    layout="vertical"
  >
    <Form.Item
      label="Code"
      name="code"
      rules={[{
        required: true, max: 70, validator: state.freezeCodeFeild ? undefined : (rule, value, callback) => {
          let regex = new RegExp(/^[a-z0-9_]+$/);
          checkIsCodeIsValid(value);
          if (!regex.test(value)) callback("Only small case english latters, numbers and _ is allowed."); else callback(undefined)
        }
      }]}
      hasFeedback
      validateStatus={codeValidation}
    >
      <Input disabled={state.freezeCodeFeild} />
    </Form.Item>

    <p style={{ color: 'red' }}>{!state.isCodeValid && 'Please enter a valid code'} </p>
    {
      state.isCodeValid && <>
        <Form.List name="languages" initialValue={[{ language_code: undefined }]}>
          {(fields, { add, remove, move }) => (
            <>
              {fields.map((field, index) => (
                <Space style={{ display: 'flex', flexDirection: 'row', padding: '10px', justifyContent: 'space-between', background: ((index + 1) % 2 ? 'rgb(234 236 238)' : 'rgb(217 221 225)') }}>
                  <Form.Item
                    label="Select language "
                    name={[field.name, 'language_code']}
                    rules={[...REQUIRED_RULE, {
                      validator(rule, value, callback) {
                        checkIsCodeIsUnique(value.value).then((isUnique) => {
                          if (isUnique || state.freezeLanguageFeild) {
                            callback(undefined)
                          } else {
                            callback("This language code is not allowed")
                          }
                        }).catch(() => {
                          callback("try again ")
                        })
                      },
                    }]}
                  >
                    <Select
                      style={{ width: 200 }}
                      showSearch
                      optionFilterProp="label"
                      labelInValue
                      disabled={state.freezeLanguageFeild}
                      onChange={(value) => {
                        dispatch({ type: types.UPDATE_SUPPPORTED_LANGUAGES, payload: form.getFieldValue('languages').map((language: any) => language.language_code.value) })
                      }}
                    >
                      {state.supportedlanguages.map((item) => <Select.Option label={item.alt_name} value={item.value}>{item.alt_name} ({item.name})</Select.Option>)}
                    </Select>
                  </Form.Item>
                  {fields.length > 1 && <Button onClick={() => {
                    remove(field.name);
                    setTimeout(() => {
                      dispatch({ type: types.UPDATE_SUPPPORTED_LANGUAGES, payload: form.getFieldValue('languages').map((language: any) => language.language_code.value) })
                    }, 100)
                  }}><MinusCircleOutlined /> remove </Button>}
                </Space>
              ))}
              <Form.Item style={{ marginTop: '20px' }}>
                {
                  !state.freezeLanguageFeild && <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add language
                  </Button>
                }
              </Form.Item>

            </>
          )}
        </Form.List>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <FileUpload rules={REQUIRED_RULE} name={'largeThumbnail'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={getFileUploadUrl} title={'upload image'} />
          <FileUpload rules={REQUIRED_RULE} name={'miniThumbnail'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={getFileUploadUrl} title={'upload image'} />
          <FileUpload rules={REQUIRED_RULE} name={'smallRoundThumbnail'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={getFileUploadUrl} title={'upload image'} />
          <FileUpload rules={REQUIRED_RULE} name={'smallSquareThumbnail'} accept={SUPPORTED_IMAGE_ACCEPT_TYPE} getS3PathApi={getFileUploadUrl} title={'upload image'} />
        </div>
        <Form.Item
          label="Position"
          name="position"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Temple Speciality"
          name="displayStatus"
          rules={[{ required: true }]}
        >
          <Radio.Group >
            <Radio value={'festive_special'}>Festive Special</Radio>
            <Radio value={'pre_selected'}>Pre Selected</Radio>
            <Radio value={'none'}>Default</Radio>
          </Radio.Group>
        </Form.Item>
        {/* <Card style={{ marginBottom: "20px" }}>
          <h3 style={{ textAlign: "center", marginBottom: "30px" }}>Enter Loader details</h3>
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item
                label="Default title"
                name={["loader_text", "default", "title"]}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={11}>

              <Form.Item
                label="Default subtitle"
                name={["loader_text", "default", "subtitle"]}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="English title"
                name={["loader_text", "english", "title"]}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="English subtitle"
                name={["loader_text", "english", "subtitle"]}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Duration "
                name={["loader_text", "duration"]}
                rules={[{ required: true }]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
        </Card> */}
        <Form.Item>
          <Button type="primary" htmlType="submit" >Submit</Button>
          {!editGod && <Button style={{ marginLeft: '10px' }} htmlType="submit" onClick={resetForm}>Reset</Button>}
        </Form.Item>
      </>
    }
  </Form >
}

export default GodForm;