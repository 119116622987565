import { useMemo } from 'react';
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import 'antd/dist/antd.css';
import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, Switch, TablePaginationConfig, Tag, message, } from 'antd';
import { SocialScreenContainer } from '@a4b/ui/src/admin';
import { openNotification } from '@/utils';
import { Shrine } from '@/../../../packages/a4b-api/src/modules/Temple/types';
import { debounce } from 'lodash';
import FileUpload, { getEditImageFiles, getPathFormFile, getTypeFormFile } from '@a4b/ui/src/modules/admin/components/FileUpload';
import useAntdPagination from '@/hooks/useAntdPagination';
import { FORM_CONFIG, FORM_ITEM_TYPE, GOD_ITEMS, GOD_ITEM_TYPES } from './dynamic-items-definitions';
import moment from 'moment';
import styled from 'styled-components';
import { REQUIRED_RULE } from '../../../../utils/form.validation';
import { renderDateTime } from '../../../../components/TableHelpers/ColumnHelper';
import { RangePickerProps } from 'antd/lib/date-picker';
import { SCHEDULE_ITEM_ID } from '../../../../utils/constants';

const requiredRule = [{ required: true }]

const ITEM_FORM_DEFAULT_FEILDS_SET = new Set(['code', 'type', 'access_type', 'thumbnail', 'attachment', 'coins_cost', 'validity_period', 'available_for_all_gods', 'days_active', 'schedule_at', 'active']);

const FREE_ITEM_TYPES = new Set([GOD_ITEM_TYPES.BACKGROUND, GOD_ITEM_TYPES.ARCH_LAYER, GOD_ITEM_TYPES.FLOOR_LAYER, GOD_ITEM_TYPES.MIDDLE_LAYER, GOD_ITEM_TYPES.CTA, GOD_ITEM_TYPES.ITEM_BASE])

const storeSelectedGodItem = (selectedGodItem: string) => {
  localStorage.setItem("SELECTED_GOD_ITEM", selectedGodItem);
}

const getStoredSelectedGodItem = () => {
  return localStorage.getItem("SELECTED_GOD_ITEM");
}

const CenterAlignDiv = styled.div`
  display: flex;
  justify-content: center;
`

const disabledDate: RangePickerProps['disabledDate'] = current => {
  // Can not select days before today and today
  return current && current < moment().subtract(1, "day").endOf('day');
};

const ShrineItemsSchedules = () => {
  const { networkInstance } = useAppContext();
  const [isCodeUnique, setIsCodeUnique] = useState(false);
  const [showDrower, setShowDrower] = useState(false);
  const [defaultItem, setDefaultItem] = useState<undefined | Shrine.Item>();
  const [associateItemId, setAssociateItemId] = useState<undefined | string>();
  const [schedules, setSchedules] = useState<Shrine.GodItemSchedule[]>([])
  const { pagination, setPagination } = useAntdPagination();
  const [form] = Form.useForm();
  const type = Form.useWatch('type', form);
  const access_type = Form.useWatch('access_type', form);
  const { itemType, godId } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [freezeType, setFreezeType] = useState(false);
  const [selectedGodItem, setSelectedGodItem] = useState<GOD_ITEM_TYPES>(getStoredSelectedGodItem() as GOD_ITEM_TYPES || GOD_ITEM_TYPES.CHADAVA_ICON);
  const loadSchedule = useCallback(async () => {
    try {
      const res = await networkInstance.clientWithHeaders.shrineApi.getItemSchedule();
      setSchedules(res.data.data);
      if (pagination.total === -1) {
        setPagination({ ...pagination, total: res.data.data.length })
      }
    } catch (error) {

    }
  }, [networkInstance.clientWithHeaders.shrineApi, pagination, setPagination])

  const loadItem = useCallback(async () => {
    try {
      const resp = await networkInstance.clientWithHeaders.shrineApi.getItem(SCHEDULE_ITEM_ID);
      setDefaultItem(resp.data.data.item)
    } catch (error) {
    }
  }, [networkInstance.clientWithHeaders.shrineApi])

  useEffect(() => {
    loadSchedule()
    loadItem();
  }, [loadItem, loadSchedule])

  useEffect(() => {
    const itemCopy: any = { ...defaultItem };
    // @ts-ignore
    itemCopy['attachment'] = defaultItem?.attachment ? getEditImageFiles([defaultItem['attachment']]) : undefined;
    itemCopy['thumbnail'] = defaultItem?.thumbnail ? getEditImageFiles([defaultItem['thumbnail']]) : undefined;

    if (defaultItem?.property) {
      Object.keys(defaultItem.property).forEach((propertyKey) => {
        // @ts-ignore
        const config = FORM_CONFIG[defaultItem.type];
        if (config[propertyKey]?.type === FORM_ITEM_TYPE.STRING || config[propertyKey]?.type === FORM_ITEM_TYPE.NUMBER) {
          // @ts-ignore 
          itemCopy[propertyKey] = defaultItem.property[propertyKey]

        } else {
          //@ts-ignore
          if (propertyKey === 'states') {
            //@ts-ignore 
            itemCopy['0'] = defaultItem.property[propertyKey]['0'] ? getEditImageFiles([defaultItem.property[propertyKey]['0']]) : undefined
            //@ts-ignore 
            itemCopy['100'] = defaultItem.property[propertyKey]['100'] ? getEditImageFiles([defaultItem.property[propertyKey]['100']]) : undefined
            return
          }

          // @ts-ignore
          itemCopy[propertyKey] = defaultItem.property[propertyKey] && defaultItem.property[propertyKey].type !== '' ?
            //@ts-ignore
            getEditImageFiles([defaultItem.property[propertyKey]])
            : undefined
        }
      })
    }
    if (defaultItem?.access_type === 'coins_based') {
      itemCopy['coins_cost'] = defaultItem.coins_threshold
      itemCopy['validity_period'] = defaultItem.validity_prd_secs
    }
    if (defaultItem?.access_type === 'days_active_based') {
      itemCopy['coins_cost'] = defaultItem.coins_threshold
      itemCopy['validity_period'] = defaultItem.validity_prd_secs
      itemCopy['days_active'] = defaultItem.days_active_threshold
    }

    itemCopy['classification'] = defaultItem?.property?.['classification'] ? defaultItem?.property?.["classification"] : "others"

    // handling audio attechment url for item type is prayer 
    if (itemCopy.type === GOD_ITEM_TYPES.PRAYER) {
      itemCopy['audio_attachment'].path = itemCopy['audio_attachment'].url.replace(/^.*\/\/[^\/]+/, '');
      delete itemCopy['audio_attachment'].url
    }

    // handling date range for vishash 
    if (itemCopy.type === GOD_ITEM_TYPES.VISHESH) {
      // @ts-ignore
      itemCopy['date_range'] = [moment(defaultItem['start_time']), moment(defaultItem['end_time'])]
    }
    if (itemCopy.schedule_at) {
      itemCopy['schedule_at'] = moment(itemCopy.schedule_at)
    }
    form.setFieldsValue(itemCopy)
  }, [defaultItem, form])


  const handleDrowerClose = useCallback(() => {
    setShowDrower(false);
    setIsEdit(false);
  }, [])

  const handleDrowerOpen = useCallback(() => {
    setShowDrower(true);
  }, [setShowDrower])

  const createUpdateSchedule = useCallback(async (values) => {
    if (defaultItem?.id === undefined) return null;
    const payload: Shrine.CreateSchedulePayload = {
      code: values.code,
      type: values.type,
      attachment: {
        path: getPathFormFile(values.attachment),
        type: getTypeFormFile(values.attachment)
      },
      property: {},
      access_type: values.access_type,
      available_for_all_gods: !!values.available_for_all_gods,
      schedule_at: values.schedule_at.valueOf(),
      item_id: defaultItem.id
    }
    if (values.thumbnail) {
      payload['thumbnail'] = {
        path: getPathFormFile(values.thumbnail),
        type: getTypeFormFile(values.thumbnail)
      }
    }

    if (values.access_type === 'coins_based') {
      payload['coins_cost'] = values.coins_cost
      payload['validity_period'] = values.validity_period
    }
    if (values.access_type === 'days_active_based') {
      payload['coins_cost'] = values.coins_cost
      payload['days_active'] = values.days_active
      payload['validity_period'] = values.validity_period
    }

    const property: { [key: string]: string | { type: string, path: string } } = {};
    Object.keys(values).forEach((fieldName: string) => {
      if (!ITEM_FORM_DEFAULT_FEILDS_SET.has(fieldName)) {
        // @ts-ignore 
        const godItem = FORM_CONFIG[values.type]
        if (godItem[fieldName].type === FORM_ITEM_TYPE.DATE_RANGE) {
          // @ts-ignore
          payload[godItem[fieldName].field_names[0]] = moment(values[fieldName][0]).startOf('day').valueOf()
          // @ts-ignore
          payload[godItem[fieldName].field_names[1]] = moment(values[fieldName][1]).endOf('day').valueOf()
          return
        }
        if (godItem[fieldName].type === FORM_ITEM_TYPE.STRING || godItem[fieldName].type === FORM_ITEM_TYPE.NUMBER) {
          property[fieldName] = values[fieldName]
        } else {
          property[fieldName] = {
            path: getPathFormFile(values[fieldName]),
            type: getTypeFormFile(values[fieldName])
          }
        }
      }
    })
    if (values.type === GOD_ITEM_TYPES.IGNITABLE) {
      //@ts-ignore
      property['states'] = {};
      //@ts-ignore
      property['states']['0'] = property['0'];
      //@ts-ignore
      property['states']['100'] = property['100'];
      delete property['100'];
      delete property['0'];
    }
    payload['property'] = property;

    // removing coin cost and validity period for prayer type
    if (payload.type === GOD_ITEM_TYPES.PRAYER) {
      delete payload['coins_cost']
      delete payload['validity_period']
    }
    try {
      if (isEdit) {
        await networkInstance.clientWithHeaders.shrineApi.putSchedule(payload);
      } else {
        await networkInstance.clientWithHeaders.shrineApi.postSchedule(payload);
      }
      openNotification('success', { message: 'Scheduled successfully!' })
      form.resetFields();
      setShowDrower(false);
      loadSchedule();
    } catch (error: any) {
      openNotification('error', { message: 'Schedule failed!', description: error.toString(), })
    }
  }, [defaultItem, form, isEdit, loadSchedule, networkInstance.clientWithHeaders.shrineApi])

  const checkIsCodeUnique = debounce(useCallback(async (code) => {
    try {
      const res = await networkInstance.clientWithHeaders.shrineApi.checkGodItemCodeUnique(code);
      setIsCodeUnique(res.data.data.is_unique);
    } catch (error) {
      console.log(error)
      setIsCodeUnique(false);
    }
  }, [networkInstance.clientWithHeaders.shrineApi]), 1000)


  const getFileUploadUrl = useCallback(async (file) => {
    if (!freezeType) {
      setFreezeType(true);
    }
    // try {
    const res = await networkInstance.clientWithHeaders.shrineApi.getS3SignedUrl(file.name, 'items', form.getFieldValue('type'));
    return res.data.data.info
    // } catch (error) {

    // }
  }, [form, freezeType, networkInstance.clientWithHeaders.shrineApi])

  const [messageApi, contextHolder] = message.useMessage();


  const info = useMemo(() => () => {
    messageApi.info('Copied!!');
  }, [messageApi]);

  const code = Form.useWatch('code', form);


  const drowerContent = useMemo(() => {
    return <Form
      form={form}
      onFinish={createUpdateSchedule}
      layout="vertical"
    >
      <Form.Item
        label="God item type"
        name="type"
        rules={[{ required: true, message: 'Please input name!' }]}
      >
        <Select
          onChange={(val) => {
            // setting default value for code 
            form.setFieldValue('code', val + '_')

            // setting static access type free for free items 
            if (FREE_ITEM_TYPES.has(val)) {
              form.setFieldValue('access_type', 'free')
            }
          }}
          disabled={!!defaultItem}
        >
          {GOD_ITEMS.map((item) => <Select.Option value={item}>{item}</Select.Option>)}
        </Select>
      </Form.Item>
      {
        type && <> <Form.Item
          label="Code"
          name="code"
          rules={defaultItem ? [] : [{
            required: true, validator: (rule, value, callback) => {
              let regex = new RegExp(/^[a-z0-9_]+$/);
              checkIsCodeUnique(value);
              if (!regex.test(value)) callback("Only small case english latters, numbers and _ is allowed."); else callback(undefined);
            }
          }]}

          hasFeedback
          validateStatus={defaultItem ? 'success' : isCodeUnique ? 'success' : 'error'}
        >
          <Input maxLength={32} disabled={!!defaultItem} />
        </Form.Item>
          <p style={{ color: 'red' }}>{!defaultItem && !isCodeUnique && 'Please enter a valid code'} </p>
          {
            type !== GOD_ITEM_TYPES.PRAYER &&
            <FileUpload rules={requiredRule} name={'thumbnail'} accept={`.png,.jpg,.jpeg,.gif,.webp,${code === 'cta_right_2_new_default' ? 'application/JSON' : ''}`} getS3PathApi={getFileUploadUrl} title={'upload image'} />
          }
          <FileUpload rules={requiredRule} name={'attachment'} accept={`.png,.jpg,.jpeg,.gif,.webp,${code === 'cta_right_2_new_default' ? 'application/JSON' : ''}`} getS3PathApi={getFileUploadUrl} title={'upload image'} />
          {
            //@ts-ignore
            type && FORM_CONFIG[type].propertyForm && FORM_CONFIG[type].propertyForm({ getFileUploadUrl })
          }
          <Form.Item
            label="Access types"
            name="access_type"
            rules={[{ required: true }]}
          >
            <Radio.Group disabled={FREE_ITEM_TYPES.has(type) || ((defaultItem?.access_type === 'free' || defaultItem?.access_type === 'vip'))} >
              <Radio disabled={!!defaultItem} value={'free'}>Free</Radio>
              <Radio value={'coins_based'}>Coins based</Radio>
              <Radio value={'days_active_based'}>Days active based</Radio>
              <Radio disabled={!!defaultItem} value={'vip'}>VIP</Radio>
            </Radio.Group>
          </Form.Item>
          {
            access_type === 'coins_based' && <>
              <Form.Item
                label="Coins cost"
                name="coins_cost"
                rules={[{ required: true }]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Validity Period (In seconds)"
                name="validity_period"
                rules={[{ required: true }]}
              >
                {/* <DatePicker
              format="MM-DD HH:mm:ss"
            // showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
            /> */}
                <Select style={{ width: '200px' }} >
                  <Select.Option value={43200}> 12 Hours </Select.Option>
                  <Select.Option value={86400}> 1 Day </Select.Option>
                  <Select.Option value={777600}> 9 Days </Select.Option>
                  <Select.Option value={31536000}> 1 year </Select.Option>
                </Select>
              </Form.Item>
            </>

          }
          {
            access_type === 'days_active_based' && <>
              <Form.Item
                label="Coins cost"
                name="coins_cost"
                rules={[{ required: true }]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Days active milestone"
                name="days_active"
                rules={[{ required: true }]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Validity Period (In seconds)"
                name="validity_period"
                rules={[{ required: true }]}
              >
                <Select style={{ width: '200px' }} >
                  <Select.Option value={43200}> 12 Hours </Select.Option>
                  <Select.Option value={86400}> 1 Day </Select.Option>
                  <Select.Option value={777600}> 9 Days </Select.Option>
                  <Select.Option value={31536000}> 1 year </Select.Option>
                </Select>
              </Form.Item>
            </>
          }
        </>
      }
      <Form.Item
        label={"Schedule at"}
        name={"schedule_at"}
        rules={REQUIRED_RULE}
      >
        <DatePicker disabled={isEdit} disabledDate={disabledDate} showTime></DatePicker>
      </Form.Item>
      <Form.Item
        label={"Available for all gods"}
        name={"available_for_all_gods"}
        valuePropName='checked'
      >
        <Checkbox></Checkbox>
      </Form.Item>
      <Form.Item
        label={"Active"}
        name={"active"}
        valuePropName='checked'
      >
        <Checkbox></Checkbox>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" >Submit</Button>
      </Form.Item>
    </Form>
  }, [form, createUpdateSchedule, defaultItem, type, isCodeUnique, code, getFileUploadUrl, access_type, isEdit, checkIsCodeUnique])

  const columns = useMemo(() => [
    {
      title: 'Item id',
      dataIndex: 'item_id',
      width: 300,
      render: (value: string) => {
        return <Tag>{value}</Tag>
      }
    },
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnail',
      render: (thumbnail: { type: string, url: string }) => { return <img src={thumbnail.url} height={50} width={50} alt={'god image'} /> }
    },
    {
      title: 'Attachments',
      dataIndex: 'attachment',
      render: (attachment: { type: string, url: string }) => <img src={attachment.url} height={50} width={50} alt={'god image'} />
    },
    {
      title: 'Access type',
      dataIndex: 'access_type'
    },
    {
      title: "Schedule at",
      dataIndex: "schedule_at",
      render: renderDateTime
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (id: string, data: Shrine.Item) => <>
        {/* <Switch checked={data.active} onClick={() => { data.active ? deactivateGod(id) : activateGod(id) }} checkedChildren={'Deactivate'} unCheckedChildren="Activate" /> */}
        <Button type="link" onClick={() => {
          setDefaultItem({
            // @ts-ignore
            id: data.item_id, ...data
          }); handleDrowerOpen(); setIsEdit(true);
        }} > Edit </Button>
      </>
    },
  ], [handleDrowerOpen]);

  const tableOnChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    setPagination(pagination)
  }

  const filterUi = useMemo(() => {
    return <Select
      value={selectedGodItem}
      style={{ width: 300 }}
      onChange={(val: GOD_ITEM_TYPES) => { storeSelectedGodItem(val); setPagination({ ...pagination, total: -1 }); setSelectedGodItem(val) }}
    >
      {GOD_ITEMS.map((item) => <Select.Option value={item}>{item}</Select.Option>)}
    </Select>
  }, [pagination, selectedGodItem, setPagination])

  const drowerProps = useMemo(() => ({ title: associateItemId ? 'Associate item to god' : defaultItem ? 'Update god item' : 'Create god item', visible: showDrower, onClose: handleDrowerClose, width: 900 }), [associateItemId, defaultItem, handleDrowerClose, showDrower])

  return (
    <>
      {contextHolder}
      <SocialScreenContainer
        title={'Schedule list'}
        drawerProps={drowerProps}
        drawerContent={drowerContent}
        content={schedules.length === 0 ? <CenterAlignDiv>
          <Button onClick={() => { handleDrowerOpen() }} type='primary'> Add schedule</Button>
        </CenterAlignDiv>
          : undefined
        }
        tableProps={
          schedules.length > 0 ?
            { columns: columns, dataSource: schedules, pagination: pagination, onChange: tableOnChange, size: 'small', scroll: { y: 650 } }
            : undefined
        }
      // extra={<Button onClick={handleDrowerOpen}>Create schedule </Button>}
      // filtersUI={filterUi}
      >
      </SocialScreenContainer>
    </>
  )
}

export default withDefaultLayout(ShrineItemsSchedules)
