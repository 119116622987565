import PrivateRoute from '@/components/Routes/PrivateRoute'
import { Route, Routes } from 'react-router-dom'

//offerings
import BatchList from '../offerings/BatchList'
import CreateTemple from '../offerings/CreateTemple'
import HubList from '../offerings/HubList'
import ManageItem from '../offerings/ManageItem'
import ManagePages from '../offerings/ManagePages'
import ManagePageSections from '../offerings/ManagePageSections'
import ManageTemples from '../offerings/ManageTemples'
import PoojaOrders from '../offerings/PoojaOrders'
import TempleList from '../offerings/TempleList'
import ManageCampaigns from '../offerings/ManageCampaigns'
import ManageUserSegments from '../offerings/ManageUserSegments'
import ContentScheduler from '../offerings/ContentScheduler'
import ManageScheduler from '../offerings/ManageScheduler'
import ManageS3Upload from '../offerings/ManageS3Upload'
import ManageUserScreen from '../offerings/ManageUsers'
import ManageTempleScreen from '../offerings/ManageTempleShrines'
import ManageBatchVideoQC from '../offerings/ManageBatchVideoQc'

//Puja
import BatchListPuja from '../puja/ManageBatches/screens/BatchList'
import CreateTemplePuja from '../puja/ManageStores/CreateStore'
import HubListPuja from '../puja/HubList'
import ManageItemPuja from '../puja/ManageItem'
import ManagePagesPuja from '../puja/ManagePageSections/screens/ManagePages'
import ManagePageSectionsPuja from '../puja/ManagePageSections'
import ManageTemplesPuja from '../puja/ManageStores/StoreList'
import PoojaOrdersPuja from '../puja/ManageOrders'
import TempleListPuja from '../puja/TempleList'
import ManageUserSegmentsPuja from '../puja/ManageUserSegments'
import ManageS3UploadPuja from '../puja/ManageS3Upload'
import ManagePujaUpdates from '../puja/ManagePujaUpdates'
import Filters from '../puja/ManageCategoryFilter'
import HomePage from '../HomePage'
import ManageBatches from '../puja/ManageBatches'

//Astro
import AstrologerList from '@/modules/monetisation/Astrologer/ManageAstrologer/AstrologerList'
import ManageAstrologer from '@/modules/monetisation/Astrologer/ManageAstrologer/index'
import AstrologerPageSection from '@/modules/monetisation/Astrologer/AstrologerPageSection'
import ManageCalls from '@/modules/monetisation/Astrologer/ManageCalls'

//wallet
import RechargePackages from '@/modules/wallet/screens/RechargePackages/';

const MonetisationRoutes = () => {
  return (
    <Routes>
      <Route path='monetisation' element={<PrivateRoute />}>
        <Route element={<HubList />} />
        <Route path='campaigns' element={<PrivateRoute />}>
          <Route path='manage-campaigns' element={<ManageCampaigns />} />
        </Route>
        <Route path='offerings' element={<PrivateRoute />}>
          <Route index element={<HubList />} />
          <Route path='home-page' element={<HomePage />} />

          <Route path='hub/:hubId' element={<TempleList />} />
          <Route
            path='hub/:hubId/temple/:templeId/batch'
            element={<BatchList />}
          />
          <Route path='temples' element={<ManageTemples />} />
          <Route path='items' element={<ManageItem />} />
          <Route path='manage_page_section' element={<ManagePageSections />} />
          <Route path='pages' element={<ManagePages />} />
          <Route path='temple/create' element={<CreateTemple />} />

          <Route element={<HubList />} />
          <Route path='s3-upload' element={<ManageS3Upload />} />

          <Route path='epuja' element={<PrivateRoute />}>
            <Route path='video-upload' element={<TempleList />} />
          </Route>
          <Route path='pooja-orders' element={<PrivateRoute />}>
            <Route path='orders' element={<PoojaOrders />} />
          </Route>
          <Route path='segments' element={<PrivateRoute />}>
            <Route path='manage-user-segments' element={<ManageUserSegments />} />
          </Route>
          <Route path='content' element={<PrivateRoute />}>
            <Route path='event-schedule' element={<ContentScheduler />} />
            <Route path='event-schedule/:date' element={<ManageScheduler />} />
          </Route>
          <Route path='manage-users' element={<PrivateRoute />}>
            <Route path='users' element={<ManageUserScreen />}></Route>
          </Route>
          <Route path='manage-temples' element={<ManageTempleScreen />}></Route>
          <Route path='video-qc' element={<ManageBatchVideoQC />}></Route>
        </Route>
        <Route path='puja' element={<PrivateRoute />}>
          <Route index element={<HubListPuja />} />
          <Route path='hub/:hubId' element={<TempleListPuja />} />
          <Route
            path='hub/:hubId/temple/:templeId/batch'
            element={<ManageBatches />}
          />
          <Route path='temples' element={<ManageTemplesPuja />} />
          <Route path='filters' element={<Filters />} />
          <Route path='puja-updates' element={<ManagePujaUpdates />} />
          <Route path='items' element={<ManageItemPuja />} />
          <Route path='manage_page_section' element={<ManagePageSectionsPuja />} />
          <Route path='pages' element={<ManagePagesPuja />} />
          <Route path='temple/create' element={<CreateTemplePuja />} />

          <Route element={<HubListPuja />} />
          <Route path='s3-upload' element={<ManageS3UploadPuja />} />

          <Route path='epuja' element={<PrivateRoute />}>
            <Route path='video-upload' element={<TempleListPuja />} />
          </Route>
          <Route path='pooja-orders' element={<PrivateRoute />}>
            <Route path='orders' element={<PoojaOrdersPuja />} />
          </Route>
          <Route path='segments' element={<PrivateRoute />}>
            <Route path='manage-user-segments' element={<ManageUserSegmentsPuja />} />
          </Route>
        </Route>
        <Route path='astrologer'>
          <Route path='astrologer-list' element={<AstrologerList />} />
          <Route path='create' element={<ManageAstrologer />} />
          <Route path='manage-page-section' element={<AstrologerPageSection />} />
          <Route path='manage-calls' element={<ManageCalls />} />
        </Route>
        <Route path='wallet'>
          <Route path='recharge-packages-list' element={<RechargePackages />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default MonetisationRoutes