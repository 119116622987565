import {
  Action,
  ActionList,
  ActionShortcuts,
  ActionV2,
  AppShare,
  ContentCollection,
  HeroArticle,
  HeroContent,
  HeroSong,
  HeroVideo,
  MenuAppVersion,
  MenuLogin,
  MenuNotification,
  MiniMandir,
  Panchang,
  SongPlaylist,
  YoutubeLive,
  YoutubeSchedule,
} from '@/entities/feeds/Widgets'
import Greeting from '@/entities/feeds/Widgets/Greeting'
import Social from '@/entities/feeds/Widgets/Social'
import Feeds from '@/network/Feeds/Feeds'
import FeedsRiddler from '@/network/Feeds/FeedsRiddler'
import { message } from 'antd'
import Cookies from 'js-cookie'
import moment from 'moment'

import { IUserProfile, LoginState } from '../entities/UserProfile'
import Property from '../entities/feeds/Property'
import { Content } from './types'
import { getAllDays } from './utilProps'

let window = (global as any).window
export const extractBasicProfile = (identity: any) => {
  try {
    let profileData: IUserProfile = {
      loginStatus: identity?.email_verified
        ? LoginState.LOGGED_IN
        : LoginState.LOGGED_OUT,
      name: identity.name,
      email: identity.email,
      imageUrl: identity.picture,
    }
    return profileData
  } catch (error) {}
}

export const loadGAuthApi = async () => {
  let gapi = (global as any).gapi
  await gapi?.auth2?.init({
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    ux_mode: 'popup',
    scope: 'profile email',
  })
}

export const logoutUser = () => {
  Cookies.remove('gauth-token')
  localStorage.removeItem('userinfo')
  window.location.href = '/'
}

export const isCreateRoute = (urlPath: string): boolean => {
  let pathName = urlPath?.substring(1)
  let urlList = pathName?.split('/')
  return urlList[urlList.length - 1] === 'create' ? true : false
}
export const parseQueryString = (url: string) => {
  const queryString = url.replace(/.*\?/, '')

  if (queryString === url || !queryString) {
    return null
  }

  const urlParams = new URLSearchParams(queryString)
  const result: any = {}

  urlParams.forEach((val, key) => {
    if (result.hasOwnProperty(key)) {
      result[key] = [result[key], val]
    } else {
      result[key] = val
    }
  })

  return result
}
export const getWidgetEntityInstance = (widgetType: string): Content => {
  switch (widgetType) {
    case 'ACTION':
      return new Action()
    case 'APP_SHARE':
      return new AppShare()
    case 'YOUTUBE_SCHEDULE':
      return new YoutubeSchedule()
    case 'PANCHANG':
      return new Panchang()
    case 'HERO_SONG':
      return new HeroSong()
    case 'MINI_MANDIR':
      return new MiniMandir()
    case 'HERO_ARTICLE':
      return new HeroArticle()
    case 'HERO_CONTENT':
      return new HeroContent()
    case 'SONG_PLAYLIST':
      return new SongPlaylist()
    case 'YOUTUBE_LIVE':
      return new YoutubeLive()
    case 'CONTENT_COLLECTION':
      return new ContentCollection()
    case 'HERO_VIDEO':
      return new HeroVideo()
    case 'MENU_NOTIFICATION':
      return new MenuNotification()
    case 'ACTION_LIST':
      return new ActionList()
    case 'ACTION_SHORTCUTS':
      return new ActionShortcuts()
    case 'MENU_APP_VERSION':
      return new MenuAppVersion()
    case 'MENU_LOGIN':
      return new MenuLogin()
    case 'ACTION_V2':
      return new ActionV2()
    case 'HERO_SOCIAL_COMMUNITY':
      return new Social()
    case 'GREETING':
      return new Greeting()
    default:
      return new Action()
  }
}
export const getWidgetsNameAndClass = (widgetType: string) => {
  switch (widgetType) {
    case 'ACTION':
      return ['Action Widget', Action]
    case 'APP_SHARE':
      return ['App Share Widget', AppShare]
    case 'YOUTUBE_SCHEDULE':
      return ['Youtube Schedule Widget', YoutubeSchedule]
    case 'PANCHANG':
      return ['Panchang Widget', Panchang]
    case 'HERO_SONG':
      return ['Hero Song Widget', HeroSong]
    case 'MINI_MANDIR':
      return ['Mini Mandir Widget', MiniMandir]
    case 'HERO_ARTICLE':
      return ['Hero Article Widget', HeroArticle]
    case 'HERO_CONTENT':
      return ['Hero Content Widget', HeroContent]
    case 'SONG_PLAYLIST':
      return ['Song Playlist Widget', SongPlaylist]
    case 'YOUTUBE_LIVE':
      return ['Youtube Live Widget', YoutubeLive]
    case 'CONTENT_COLLECTION':
      return ['Content Collection Widget', ContentCollection]
    case 'HERO_VIDEO':
      return ['Hero Video Widget', HeroVideo]
    case 'MENU_NOTIFICATION':
      return ['Menu Notification Widget', MenuNotification]
    case 'ACTION_LIST':
      return ['Action List Widget', ActionList]
    case 'ACTION_SHORTCUTS':
      return ['Action Shortcuts Widget', ActionShortcuts]
    case 'MENU_APP_VERSION':
      return ['Menu App Version Widget', MenuAppVersion]
    case 'MENU_LOGIN':
      return ['Menu Login Widget', MenuLogin]
    case 'ACTION_V2':
      return ['Action V2 Widget', ActionV2]
    case 'HERO_SOCIAL_COMMUNITY':
      return ['Social Widget', Social]
    case 'GREETING':
      return ['Greeting Widget', Greeting]
    default:
      return []
  }
}
export const getReadableWidgetName = (widgetType: string) => {
  switch (widgetType) {
    case 'ACTION':
      return 'Action'
    case 'APP_SHARE':
      return 'App Share'
    case 'YOUTUBE_SCHEDULE':
      return 'Youtube Schedule'
    case 'PANCHANG':
      return 'Panchang'
    case 'HERO_SONG':
      return 'Hero Song'
    case 'MINI_MANDIR':
      return 'Mini Mandir'
    case 'HERO_ARTICLE':
      return 'Hero Article'
    case 'HERO_CONTENT':
      return 'Hero Content'
    case 'SONG_PLAYLIST':
      return 'Song Playlist'
    case 'YOUTUBE_LIVE':
      return 'Youtube Live'
    case 'CONTENT_COLLECTION':
      return 'Content Collection'
    case 'HERO_VIDEO':
      return 'Hero Video'
    case 'MENU_NOTIFICATION':
      return 'Menu Notification'
    case 'ACTION_LIST':
      return 'Action List'
    case 'ACTION_SHORTCUTS':
      return 'Action Shortcuts'
    case 'MENU_APP_VERSION':
      return 'Menu App Version'
    case 'MENU_LOGIN':
      return 'Menu Login'
    case 'ACTION_V2':
      return 'Action V2'
    case 'HERO_SOCIAL_COMMUNITY':
      return 'Social Community'
    case 'GREETING':
      return 'Greetings'
    default:
      return []
  }
}
export const isErrorPresent = (errorList: any[]) => {
  return errorList.some(item =>
    item && item.errors && item.errors.length ? true : false,
  )
}
export const cyclicDurationSerialize = (cyclic_duration: any) => {
  return Number(cyclic_duration.format('ss'))
}
export const cyclicDurationDeSerialize = (cyclic_duration: any) => {
  return moment(cyclic_duration, 'ss')
}

export const getQueryParamByObject = (filtersData: any) => {
  let keys = Object.keys(filtersData)
  let searchParams = ''
  for (let key of keys) {
    if (Array.isArray(filtersData[key])) {
      let duplicateQuery = ''
      let query = filtersData[key].map((item: string) => {
        return `${key}=${item}`
      })
      duplicateQuery = query.join('&')
      searchParams =
        searchParams === ''
          ? duplicateQuery
          : `${searchParams}&${duplicateQuery}`
    } else {
      searchParams =
        searchParams === ''
          ? `${key}=${filtersData[key]}`
          : `${searchParams}&${key}=${filtersData[key]}`
    }
  }
  return searchParams
}
export const getTimeInSecond = (time: string) => {
  let [hour, minute] = time.split(':')
  return Number(hour) * 3600 + Number(minute) * 60
}

export const getAllDaysSelectionValues = (
  value: string[],
  previousValue: string[],
) => {
  if (
    !value.includes('All') &&
    value.length === 7 &&
    previousValue.includes('All')
  ) {
    return { validDays: [], returnFlag: true }
  }
  if (
    (value.includes('All') && !previousValue.includes('All')) ||
    (!value.includes('All') && value.length === 7)
  ) {
    let days = [...getAllDays, 'All']
    return { validDays: days, returnFlag: true }
  }

  if (value.includes('All') && value.length <= 8) {
    let allRemoved: string[] = value.filter(item => item !== 'All')
    return { validDays: allRemoved, returnFlag: true }
  }
  if (
    value.includes('All') &&
    value.length === 7 &&
    previousValue.includes('All')
  ) {
    let days = [...getAllDays, 'All']
    return { validDays: days, returnFlag: true }
  }

  return { validDays: value, returnFlag: false }
}

export const throwError = (message: string) => {
  throw new Error(message)
}

export const masterWidgetOptions = (master_widgets: any[]): any[] => {
  let options = master_widgets?.map((item: Property) => ({
    value: item,
    label: item,
  }))
  return options?.length ? options : []
}
export const referenceCodeValidator = async (rule: any, value: any) => {
  if (!value) return Promise.reject('Reference code required!')
  let pattern = /^(?:[A-Z0-9]+_{0,1})+[A-Z0-9]+$/i
  if (!pattern.test(value)) {
    return Promise.reject(
      'Reference code must be only alphanumeric and underscore will be allowed in the middle.',
    )
  }
  if (value && value.length > 64) {
    return Promise.reject(
      'Reference code must be lessthan or equal to 64 chars',
    )
  }
}
export const getFeedsCarnageInstance = (apiInstance: any): Feeds =>
  new Feeds(apiInstance)
export const getFeedsRiddlerInstance = (apiInstance: any): FeedsRiddler =>
  new FeedsRiddler(apiInstance)

export const getRiddlerCdnAbsoluteUrl = (content: any) =>
  `${process.env.REACT_APP_RIDDLER_CDN_API_URL}/${content?.s3_path}`

/**
 * Changes camel case to a human readable format. So helloWorld, hello-world and hello_world becomes "Hello World".
 * */
export const prettifyCamelCase = (value = ''): string => {
  let output = ''
  const len = value.length
  let char: string

  for (let i = 0; i < len; i += 1) {
    char = value.charAt(i)

    if (i === 0) {
      output += char.toUpperCase()
    } else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
      output += ` ${char}`
    } else if (char === '-' || char === '_') {
      output += ' '
    } else {
      output += char
    }
  }

  return output
}

export const b64DecodeUnicode = (str: string) =>
  decodeURIComponent(
    Array.prototype.map
      .call(
        atob(str),
        c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2),
      )
      .join(''),
  )

export const parseJwt = (token: string) =>
  JSON.parse(
    b64DecodeUnicode(token.split('.')[1].replace('-', '+').replace('_', '/')),
  )

export function isValidHttpUrl(path: string) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // fragment locator
  return !!pattern.test(path)
}

export const titleCase = (s: string) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase(),
  )

export const formatDateToIsoString = (formDate: any) => {
  const processDate = new Date(formDate)
  let processed_date
  if (isNaN(processDate.getTime())) {
    processed_date = new Date()
  } else {
    processed_date = new Date(formDate)
  }
  return processed_date.toISOString()
}

export const formatJson = (jsonString: string) => {
  try {
    const parsedJson = JSON.parse(jsonString)
    const prettyJson = JSON.stringify(parsedJson, null, '\t')
    return prettyJson
  } catch (e) {
    return jsonString
  }
}

export const downloadVideo = async (mediaUrl: string, fileName: string) => {
  try {
    const response = await fetch(mediaUrl)

    if (!response.ok) {
      throw new Error('Failed to fetch the video')
    }

    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.mp4`)

    document.body.appendChild(link)

    message.destroy()
    message.loading('Download started!', 3)
    message.info('Check browser downloads for status.', 3)

    link.click()

    window.URL.revokeObjectURL(url)
    document.body.removeChild(link)
  } catch (error) {
    message.destroy()
    message.error('Video download failed', 3)
    console.error('Failed to download video', error)
  }
}
